import ArcTime from './ArcTime'
import events from '../years/current.json'

const TwentyFour = () => {
  return (
    <>
    <ArcTime events={events} title="2024 Timeline ⏳"/>
    </>
  )
}
export default TwentyFour