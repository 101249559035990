import Bitcoin from './Bitcoin'


const Home = () => {
  return (
    <>
    <Bitcoin/>
    
    
    </>
  )
}
export default Home