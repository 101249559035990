import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import TwentyFour from './components/TwentyFour';
import logo from './assets/logo.png';



function App() {
  


  return (
    <Router>
      <div>
        <nav className="nav-container">
          <div className="logo-container">
            <Link to="/" id="links">
              <div className='headerLogo'>
                <img src={logo} alt="Bitcoin Sprinkle" className='logo' />
                <span className="logo-text" id="logo-link">WenDip?</span>
              </div>
            </Link>
            
          </div>
          
         
        </nav>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="https://wendip.printify.me/" element={<p>WenDip Store</p>}/>
          <Route path="/2024" element={<TwentyFour />} />
          <Route path="#" element={<div>2025</div>} />
          <Route path="#" element={<div>2026</div>} />
        </Routes>
      </div>
      <footer className='footer'>
        <div>Donate sats at ⚡️ bitcoinsprinkles@getalby.com</div>
      </footer>
    </Router>
  );
}

export default App;
